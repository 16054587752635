import React from "react"
import Helmet from "react-helmet"
import { withPrefix, Link } from "gatsby"
// Add defer="true" to modest_proposal.js in production

export default() =>
<div className="modest-proposal-chart">
<Helmet>
        <script src="https://d3js.org/d3.v5.min.js"></script>
        <script defer="true" src={withPrefix('seriously-capital-proposal.js')} type="text/javascript" />
</Helmet>
</div>
